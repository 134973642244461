.body{
    margin: 10%;
}

.name{
    margin-bottom: 5%;
}

.link{
    text-decoration: none;
    color: red;
}

